import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import PROFILE_PHOTO_USER from "../../../Assets/Images/Common/avtar.png";
import CustomAction from "../../../Components/CustomAction/CustomAction";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";
import Constant from "../../../Utils/Constant";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AddChallengesEvent from "./AddChallengesEvent";
import moment from "moment/moment";
const ChallengesEvents = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [challengesEventList, setChallengesEventList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [showChallengesEventModel, setShowChallengesEventModel] =
    useState(false);
  const [editChallengesEventData, setEditChallengesEventData] = useState(null);
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100 },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      minWidth: 80,
      renderCell: (params) => {
        return (
          <img
            src={
              params?.row?.cover_img
                ? `${Constant.BASE_URL}${params?.row?.cover_img}`
                : PROFILE_PHOTO_USER
            }
            alt="thumbnail"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "10px",
            }}
          />
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,
      renderCell: (params) => (params?.row?.title ? params?.row?.title : "--"),
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params?.row?.location || "--"}>
          <span>{params?.row?.location || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip
          title={moment().format("MM/DD/YYYY", params?.row?.date) || "--"}
        >
          <span>
            {moment().format("MM/DD/YYYY", params?.row?.date) || "--"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params?.row?.type === 1 ? (
          <Box
            sx={{
              width: { xs: "100%", lg: "150px" },
              py: 0.5,
              border: "solid #B190FF",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight:"600"
            }}
          >
            <Typography>Challenges</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: "100%", lg: "150px" },
              py: 0.5,
              border: "solid #B190FF",
              background:"#B190FF",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight:"600"
            }}
          >
             <Typography>Events</Typography>
          </Box>
        ),
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: "2rem",
            }}
          >
            <CustomAction
              showDelete={true}
              showEdit={true}
              isEdit={() => {
                handleEditChallengesEventModel(params?.row);
              }}
              isDelete={() => handleDeleteChallengesEvent(params?.row?.id)}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getChallengesEventList();
  }, [page]);
  const getChallengesEventList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_EVENTS, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        },
      });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setChallengesEventList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setChallengesEventList([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleEditChallengesEventModel = (data) => {
    setTypeCheck("EDIT");
    setShowChallengesEventModel(true);
    setEditChallengesEventData(data);
  };
  const handleOnSubmit = () => {
    setTypeCheck(null);
    getChallengesEventList();
    setShowChallengesEventModel(false);
  };
  const handleDeleteChallengesEvent = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this challenges event",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: 0,
        };
        try {
          const response = await AxiosInstance.post(
            APILIST.UPDATE_EVENTS,
            params
          );
          if (response?.data?.s === 1) {
            getChallengesEventList();
            toast.success("Challenges event Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete challenges event. Please try again.");
        }
      }
    });
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          padding: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
              fontFamily: "Outfit",
            }}
          >
            Challenges & Events
          </Typography>
          <Button
            sx={{
              width: "120px",
              borderRadius: "15px",
              textTransform: "none",
              fontSize: "16px",
              background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
              color: "black",
              padding: "0",
              margin: "0",
              height: "51px",
              "&:hover": {
                background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                color: "black",
              },
              fontFamily: "Outfit",
            }}
            onClick={() => setShowChallengesEventModel(true)}
          >
            Add
          </Button>
        </Box>
        <Box
          sx={{
            padding: "24px 0px 24px 0px",
            borderTop: "1px solid #0000003D",
          }}
        >
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={challengesEventList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
      <AddChallengesEvent
        open={showChallengesEventModel}
        onClose={() => {
          setShowChallengesEventModel(false);
          setTypeCheck(null);
        }}
        typeCheck={typeCheck}
        editChallengesEventData={editChallengesEventData}
        handleOnSubmit={handleOnSubmit}
        handleOnClose={() => {
          setShowChallengesEventModel(false);
          setTypeCheck(null);
        }}
      />
    </Fragment>
  );
};

export default ChallengesEvents;

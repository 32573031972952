
export const APILIST = {
    LOGIN_API: "/api/auth/signup-login",
    GET_USERS:"/api/user/get-all", 
    GET_BY_ID:"/api/user/get-by-id",
    GET_VIDEOS:"/api/training/get-video",
    GET_TERMS_CONDITIONS:"api/content/get-terms-condition",
    ADD_TERMS_CONDITIONS:"/api/content/update-terms-condition",
    UPDATE_ACCOUNT_USERS:"/api/user/account-status",
    GET_VIDEOS:"/api/training/get-video",
    ADD_VIDEO:"/api/training/add-video",
    EDIT_VIDEO:"/api/training/update-video",
    DELETE_VIDEO:"/api/training/update-video",
    GET_VIDEO_DETAILS:"/api/training/get-video-details",
    GET_CATEGORY:"/api/training/get-category",
    ADD_CATEGORY:"/api/training/add-category",
    DELETE_CATEGORY:"/api/training/update-category",
    UPDATE_CATEGORY:"/api/training/update-category",


    GET_PARTNER:"/api/partner/get-partner",
    ADD_PARTNER:"/api/partner/add-partner",
    EDIT_PARTNER:"/api/partner/update-partner",
    DELETE_PARTNER:"/api/partner/update-partner",


    GET_PRIVACY_POLICY:"/api/content/get-privacy-policy",
    ADD_UPDATE_PRIVACY_POLICY:"/api/content/update-privacy-policy",
    GET_TERMS_CONDITION:'/api/content/get-terms-condition',
    ADD_UPDATE_TERMS_CONDITION:'/api/content/update-terms-condition',
    GET_CONTACT_US:"/api/contactus/get",
    SEND_CONTACTUS_MESSAGE:"/api/contactus/send",
    RESOLVE_CONTACTUS:"/api/contactus/resolve",
    GET_FAQ:"/api/content/get-faq",
    UPDATE_FAQ:"/api/content/update-faq",
    ADD_FAQ:"/api/content/add-faq",

    GET_SUB_ADMIN:"/api/admin/get-sub-admin",
    ADD_SUB_ADMIN:"/api/admin/add-sub-admin",
    GET_SUB_RIGHT:"/api/admin/get-modules",
    ADD_UPDATE_SUB_RIGHT:"/api/admin/add-update-module-rights",

    GET_BLOG:"/api/blog/get-all",
    UPDATE_DELETE_BLOG:"/api/blog/update",
    CREATE_BLOG:"/api/blog/create",
    GET_BLOG_DETAILS:"/api/blog/get-media",
    ADD_BLOG_ELEMENT:"/api/blog/add-media",

    GET_BLOG_CATEGORY:"/api/blog/get-category",
    ADD_BLOG_CATEGORY:"/api/blog/add-category",
    UPDATE_DELETE_BLOG_CATEGORY:"/api/blog/update-category",

    ADD_TAGS_BLOG:"/api/blog/add-tag",
    DELETE_TAGS_BLOG:"/api/blog/update-tag",
    UPDATE_TAGS_BLOG:"/api/blog/update-tag",
    GET_BLOG_DETAILS_BY_ID:"/api/blog/get-details",
    ADD_ELEMENT_BLOG:"/api/blog/add-element",
    UPDATE_ELEMENT_BLOG:"/api/blog/update-element",

    GET_OFFER:"/api/partner/get-offer",
    ADD_OFFER:"/api/partner/add-offer",
    UPDATE_OFFER:"/api/partner/update-offer",
    DELETE_OFFER:"/api/partner/update-offer",

    // ======Workout=============
    GET_ALL_WORKOUTS:"/api/workout/get-all",
    ADD_WORKOUT:"/api/workout/add",
    ADD_WORKOUT_DETAILS:"/api/workout/add-details",
    GET_WORKOUT_PLANS :"/api/workout/get-details",
    UPDATE_DELETE_WORKOUT_DETAILS:"/api/workout/update-details",
    UPDATE_DELETE_WORKOUT:"/api/workout/update",
    
    // ======Workout Plan=============
    GET_PLAN:"/api/plan/get-all-plan",
    GET_PLAN_DETAILS:"/api/plan/get-plan-details",
    CREATE_PLAN:"/api/plan/create-plan",
    UPDATE_PLAN:"/api/plan/update-plan",
    DELETE_PLAN:"/api/plan/update-plan",
    GET_PLAN_TYPE:"/api/plan/get-category",

    // =========== Challenges and Events ============
    CREATE_EVENTS:"/api/challenge-event/create",
    UPDATE_EVENTS:"/api/challenge-event/update",
    GET_EVENTS:"/api/challenge-event/get-all",
    GET_DETAILS:"/api/Challenge-event/get-details",
}

export default APILIST

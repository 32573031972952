import { Navigate } from "react-router-dom";
import AdminLayout from "../Layout/AdminLayout/AdminLayout";
// ==========Auth Routes==========
import Login from '../Pages/Auth/Login/Login';
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from "../Pages/Auth/ResetPassword/ResetPassword";
// ==========Admin Routes==========
import Dashboard from "../Pages/Admin/Dashboard/Dashboard";
import UserManagement from "../Pages/Admin/UserManagement/UserManagement";
import VideoManagement from "../Pages/Admin/VideoManagement/VideoManagement";
import VideoCategory from "../Pages/Admin/VideoCategory/VideoCategory";
import PartnerManagement from "../Pages/Admin/PartnerManagement/PartnerManagement";
import ContentManagement from "../Pages/Admin/ContentManagement/ContentManagement";
import ContactUs from "../Pages/Admin/Contact Us/ContactUs";
import Notifications from "../Pages/Admin/Notifications/Notifications";
import SubAdmin from "../Pages/Admin/SubAdmin/SubAdmin";
import Support from "../Pages/Admin/Support/Support";
import RunnerPlan from "../Pages/Admin/RunnerPlan/RunnerPlan";
import AddRunnerPlan from '../Pages/Admin/RunnerPlan/AddRunnerPlan';
import BlogManagement from "../Pages/Admin/BlogManagement/BlogManagement";
import AddBlog from "../Pages/Admin/BlogManagement/AddBlog";
import BlogCategory from "../Pages/Admin/BlogCategory/BlogCategory";
import ChallengesEvents from "../Pages/Admin/ChallengesEvents/ChallengesEvents";
import OfferManagement from "../Pages/Admin/OfferManagement/OfferManagement";
import AllWorkOutPlan from "../Pages/Admin/WorkoutPlan/AllWorkOutPlan";
import AddWorkout from "../Pages/Admin/WorkoutPlan/AddWorkout";
const routes = [
  {
    path: "/",
    component: <Navigate to="/login" component={<Login />} />,
    exact: true,
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
    exact: true,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
    exact: true,
  },
  {
    path: "/admin/*",
    component: <AdminLayout />,
    exact: true,
  },
];
export const adminLayoutRoute = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/user",
    component: <UserManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/runner-plan",
    component: <RunnerPlan />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/workout",
    component: <AllWorkOutPlan />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/add-workout",
    component: <AddWorkout />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-workout",
    component: <AddWorkout />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/add-runner-plan",
    component: <AddRunnerPlan />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-runner-plan",
    component: <AddRunnerPlan />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/challenges-events",
    component: <ChallengesEvents />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/video",
    component: <VideoManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/category",
    component: <VideoCategory />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/partner",
    component: <PartnerManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/offer",
    component: <OfferManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/content",
    component: <ContentManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/blog",
    component: <BlogManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/blog-category",
    component: <BlogCategory />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/add-blog",
    component: <AddBlog />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/update-blog",
    component: <AddBlog />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/notifications",
    component: <Notifications />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/subadmin",
    component: <SubAdmin />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/chat",
    component: <Support />,
    exact: true,
    sidebar: false,
  },
];
export default routes;
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomSubmitButton from '../../../Components/CustomSubmitButton/CustomSubmitButton';
import CustomThumbUpload from '../../../Components/CustomFileUpload/UploadImage';
import { toast } from 'react-toastify';
import CustomDropDown from '../../../Components/CustomDropDown/CustomDropDown';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import CustomTextField from '../../../Components/CustomTextField/CustomTextField';
import { planLevelList } from '../../../Utils/CommonServices';
import Constant from '../../../Utils/Constant';
import AddWorkoutModel from './AddWorkOutModel';
import PlanWorkOutList from './PlanWorkOutList';
import useDebounce from '../../../Components/useDebounce/useDebounce';
const AddRunnerPlan = () => {
    const location = useLocation();
    const pageSize = 30;
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const [planTypeList, setPlanTypeList] = useState([]);
    const [typeCheck, setTypeCheck] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectedThumbPrev, setIsSelectedThumbPrev] = useState(null);
    const [isSelectedThumbFile, setIsSelectedThumbFile] = useState(null);
    const [isRunnerPlanDetails, setIsRunnerPlanDetails] = useState(null);
    const [isWorkoutDetailsList, setIsWorkoutDetailsList] = useState([]);
    const [isSelectedWorkoutModel, setIsSelectedWorkoutModel] = useState(false);
    const [searchWorkOut, setSearchWorkOut] = useState("");
    const debouncedSearch = useDebounce(searchWorkOut, 500);
    const [checkedWorkOut, setCheckedWorkOut] = useState({});
    const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error("Invalid file type for thumb!");
        } else {
            const file = acceptedFiles[0];
            if (file && ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(file.type)) {
                if (file.size <= 2 * 1024 * 1024) {
                    const bannerUrl = URL.createObjectURL(file);
                    setIsSelectedThumbFile(file);
                    setIsSelectedThumbPrev(bannerUrl);
                    formik.setFieldValue('thumb', file, false);
                } else {
                    toast.error(`File size exceeds the limit of 2MB`);
                }
            } else {
                toast.error("Upload Only(PNG.JPG,JPEG)");
            }
        }
    }, []);
    const getPlanType = async () => {
        try {
            const response = await AxiosInstance.get(APILIST.GET_PLAN_TYPE);
            if (response && response?.data?.s) {
                setPlanTypeList(response?.data?.r);
            } else {
                setPlanTypeList([])
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    const getByIdRunnerPlanDetails = async (runnerPID) => {
        setIsLoading(true);
        try {
            const response = await AxiosInstance.get(APILIST.GET_PLAN_DETAILS, {
                params: {
                    id: runnerPID,
                }
            });
            if (response && response?.data?.s) {
                const resData = response?.data?.r;
                formik.setValues({
                    thumb: resData?.cover_img,
                    selectPlanType: resData?.cat_id,
                    planTitle: resData?.title,
                    planLevel: resData?.level,
                    planDuration: resData?.week,
                    planDescription: resData?.description
                });
                setIsRunnerPlanDetails(resData);
                if (resData?.cover_img) {
                    setIsSelectedThumbPrev(`${Constant.BASE_URL}${resData?.cover_img}`);
                }
                getByIdWorkOutDetails(resData);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }
    const getByIdWorkOutDetails = async (planDetails) => {
        try {
            const response = await AxiosInstance.get(APILIST.GET_ALL_WORKOUTS, {
                params: {
                    week: planDetails?.week,
                    plan_id: planDetails?.id,
                    search: debouncedSearch,
                    count: page * pageSize,
                    offset: pageSize,
                }
            });
            if (response && response?.data?.s) {
                const resData = response?.data?.r;
                const Rowdata = resData?.map((data, i) => ({
                    rowid: i + 1 + page * pageSize,
                    ...data,
                }));
                setCount(response?.data?.c || 0);
                setIsWorkoutDetailsList(Rowdata);
                const initialCheckedRights = {};
                const selectedModuleIds = [];
                const selectedAccessRightIds = [];
                if (Array.isArray(resData)) {
                    resData.forEach((right) => {
                        initialCheckedRights[`${right.id}`] = right.is_added === 1;
                        if (right.is_added === 1) {
                            selectedModuleIds.push(`${right.id}`);
                        }
                        if (Array.isArray(right.access)) {
                            right.access.forEach((accessRight) => {
                                console.log('accessRight', accessRight);

                                initialCheckedRights[`access-${accessRight.id}`] = accessRight.is_added !== null;
                                if (accessRight.admin_user_id !== null) {
                                    selectedAccessRightIds.push(`${accessRight.id}`);
                                }
                            });
                        }
                    });
                }
                setCheckedWorkOut(initialCheckedRights);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        const runnerPID = location?.state?.runnerPlanId;
        if (runnerPID) {
            window.sessionStorage.setItem('PlanID', runnerPID);
            getByIdRunnerPlanDetails(runnerPID);
            setTypeCheck("Edit")
        }
    }, [location.state?.runnerPlanId]);
    useEffect(() => {
        getPlanType();
        const runnerPID = window.sessionStorage.getItem('PlanID');
        if (runnerPID) {
            getByIdRunnerPlanDetails(runnerPID);
        }
    }, []);
    useEffect(() => {
        if (isRunnerPlanDetails) {
            getByIdWorkOutDetails(isRunnerPlanDetails, debouncedSearch);
        }
    }, [debouncedSearch, isRunnerPlanDetails, page]);
    const handleOnClickWorkOutIsAdded = async (checkedWorkOut) => {
        const selectedWorkoutIDs = Object.keys(checkedWorkOut).filter(id => checkedWorkOut[id]);
        if (isRunnerPlanDetails !== null) {
            const commaSeparatedIds = selectedWorkoutIDs.join(',');
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append('id', isRunnerPlanDetails?.id);
                formData.append('workout_ids', commaSeparatedIds);
                const response = await AxiosInstance.post(APILIST.UPDATE_PLAN, formData);

                if (response?.data?.s === 1) {
                    const runnerPID = window.sessionStorage.getItem('PlanID');
                    if (runnerPID) {
                        await getByIdRunnerPlanDetails(runnerPID);
                    }
                    setIsSelectedWorkoutModel(false);
                }
            } catch (error) {
                console.error('Error in handleOnClickWorkOutIsAdded:', error);
                toast.error("An error occurred while updating the plan. Please check your network and try again.");
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.error("Please create and publish your plan before proceeding.");
        }
    };
    const handleOnClickPublish = async () => {
        if (isRunnerPlanDetails !== null) {
            const checkWorkout = isRunnerPlanDetails?.plan_details || [];
            if (checkWorkout && checkWorkout?.length > 0) {
                toast.success("Plan details updated successfully");
                sessionStorage.clear();
                navigate('/admin/runner-plan');
            } else {
                toast.error("Please create and publish your plan before proceeding.");
            }
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            thumb: null,
            selectPlanType: 0,
            planTitle: "",
            planLevel: 0,
            planDuration: "",
            planDescription: ""
        },
        validationSchema: Yup.object().shape({
            thumb: Yup.mixed().required("Thumb is required"),
            selectPlanType: Yup.mixed()
                .test("is-not-zero", "Plan type is required!", value => value !== 0)
                .required("Plan type is required!"),
            planTitle: Yup.string().required("Plan title is required"),
            planLevel: Yup.mixed()
                .test("is-not-zero", "Plan level is required!", value => value !== 0)
                .required("Plan level is required!"),
            planDuration: Yup.string().required("Plan duration is required"),
            planDescription: Yup.string().required("Plan description is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (isLoading) return;

            const formData = new FormData();
            if (isRunnerPlanDetails) {
                formData.append('id', isRunnerPlanDetails.id);
            }

            formData.append('cat_id', values.selectPlanType);
            formData.append('level', values.planLevel);
            formData.append('week', values.planDuration);
            formData.append('title', values.planTitle);
            formData.append('description', values.planDescription);
            if (isSelectedThumbFile) {
                formData.append('cover_img', isSelectedThumbFile);
            }
            try {
                const apiEndpoint = isRunnerPlanDetails ? APILIST.UPDATE_PLAN : APILIST.CREATE_PLAN;
                const response = await AxiosInstance.post(apiEndpoint, formData);
                if (response && response.data?.s) {
                    const runnerPID = isRunnerPlanDetails ? window.sessionStorage.getItem('PlanID') : response?.data?.r?.id;
                    toast.success(isRunnerPlanDetails ? "Plan Updated Successfully" : "Plan Created Successfully");
                    resetForm();
                    setIsSelectedThumbFile(null);
                    setIsSelectedThumbPrev(null);
                    window.sessionStorage.setItem('PlanID', runnerPID);
                    getByIdRunnerPlanDetails(runnerPID);
                } else {
                    toast.error(`Failed to ${isRunnerPlanDetails ? 'update' : 'create'} the plan. Please try again.`);
                }
            } catch (error) {
                toast.error(error.message || `An error occurred while ${isRunnerPlanDetails ? 'updating' : 'creating'} the plan.`);
            } finally {
                setIsLoading(false);
            }
        },
    });
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    return (
        <Fragment>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: '0px',
                left: '0px',
                backgroundColor: '#fff',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
                padding: '10px',
                zIndex: '2'
            }}>
                <IconButton sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#101010',
                    borderRadius: '8px',
                    "&:hover": {
                        backgroundColor: '#101010',
                        color: '#fff',
                    }
                }} onClick={() => {
                    navigate(-1);
                    sessionStorage.removeItem("PlanID");
                }}>
                    <ArrowBackIosNewIcon sx={{ color: '#fff', fontSize: '28px' }} />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: { xs: "20px", md: "28px" },
                        fontWeight: "500",
                        paddingBottom: "10px",
                        fontFamily: "Outfit",
                    }}
                >
                   {typeCheck ? "Update" : "Add"} Plan
                </Typography>
                <CustomSubmitButton
                    btnTitle={"Publish"}
                    type="submit"
                    sx={{ width: '130px' }}
                    loading={isLoading}
                    onSubmit={() => handleOnClickPublish()}
                />
            </Box>
            <Box sx={{
                width: '98%',
                margin: "0px auto",
                padding: '100px 0px 30px 0px',

            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            background: '#EFE9FF80',
                            padding: { xs: "15px", lg: "30px" },
                            borderRadius: '8px'
                        }}>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                formik.handleSubmit();
                                return false;
                            }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                color: "black",
                                                fontWeight: "400",
                                                textAlign: "left",
                                                fontSize: { xs: "16px", sm: "18px" },
                                                mb: '8px'
                                            }}
                                        >
                                            Banner
                                        </Typography>
                                        <CustomThumbUpload
                                            accept="image/*"
                                            onDrop={handleOnChangeThumb}
                                            selectedFile={isSelectedThumbPrev}
                                            onRemove={(e) => {
                                                e.stopPropagation();
                                                setIsSelectedThumbPrev(null);
                                                setIsSelectedThumbFile(null);
                                                formik.setFieldValue('thumb', null)
                                            }}
                                            typeCheck={typeCheck}
                                            isLoading={isLoading}
                                            errors={formik.errors.thumb}
                                        />
                                        {formik.touched.thumb && formik.errors.thumb ? (
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                    fontWeight: "400",
                                                    fontSize: { xs: "14px", sm: "14px" },
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {formik.errors.thumb}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomDropDown
                                            name='selectPlanType'
                                            value={formik.values.selectPlanType}
                                            onChange={formik.handleChange}
                                            placeholder={'Select Plan Type'}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            menuList={planTypeList}
                                            NoData='No Plan Type Available'
                                            isLoading={isLoading}
                                            getLabel={(item) => item}
                                            errors={formik.touched.selectPlanType && formik.errors.selectPlanType}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CustomTextField
                                            label="Plan Title"
                                            type="text"
                                            placeholder="Plan Title"
                                            name="planTitle"
                                            value={formik.values.planTitle}
                                            errors={formik.touched.planTitle && formik.errors.planTitle}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value.trimStart() !== value) {
                                                    e.target.value = value.trimStart();
                                                }
                                                formik.handleChange(e);
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    borderRadius: "10px",
                                                    border: formik.errors.planTitle ? "1px solid red" : "1px solid #BB9EFF",
                                                    color: "black",
                                                    fontSize: "18px",
                                                    background: "white",
                                                    fontFamily: "Outfit",
                                                    height: '56px'
                                                },
                                            }}
                                            style={{ color: "black", fontSize: "18px" }}
                                            isLoading={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <CustomTextField
                                            label="Plan Duration"
                                            type="number"
                                            min="0"
                                            max={1}
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            onKeyDown={(evt) =>
                                                ["e", "E", "+", "-"].includes(evt.key) &&
                                                evt.preventDefault()
                                            }
                                            placeholder="Plan Duration"
                                            name="planDuration"
                                            value={formik.values.planDuration}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            errors={formik.touched.planDuration && formik.errors.planDuration}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value.trimStart() !== value) {
                                                    e.target.value = value.trimStart();
                                                }
                                                formik.handleChange(e);
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    borderRadius: "10px",
                                                    border: formik.errors.planDuration ? "1px solid red" : "1px solid #BB9EFF",
                                                    color: "black",
                                                    fontSize: "18px",
                                                    background: "white",
                                                    fontFamily: "Outfit",
                                                    height: '56px'
                                                },
                                            }}
                                            style={{ color: "black", fontSize: "18px" }}
                                            isLoading={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomDropDown
                                            name='planLevel'
                                            Title={"Plan Level"}
                                            value={formik.values.planLevel}
                                            onChange={formik.handleChange}
                                            placeholder={'Select Plan Level'}
                                            menuList={planLevelList}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            NoData='No Plan Level Available'
                                            isLoading={isLoading}
                                            getLabel={(item) => item}
                                            errors={formik.touched.planLevel && formik.errors.planLevel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomTextField
                                            label="Plan Description"
                                            type="text"
                                            placeholder="Plan Description"
                                            name="planDescription"
                                            value={formik.values.planDescription}
                                            onBlur={formik.handleBlur}
                                            onKeyUp={formik.handleBlur}
                                            multiline={true}
                                            rows={3}
                                            errors={formik.touched.planDescription && formik.errors.planDescription}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (value.trimStart() !== value) {
                                                    e.target.value = value.trimStart();
                                                }
                                                formik.handleChange(e);
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    borderRadius: "10px",
                                                    border: formik.errors.planDescription ? "1px solid red" : "1px solid #BB9EFF",
                                                    color: "black",
                                                    fontSize: "18px",
                                                    background: "white",
                                                    fontFamily: "Outfit",

                                                },
                                            }}
                                            style={{ color: "black", fontSize: "18px" }}
                                            isLoading={isLoading}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <CustomSubmitButton
                                                btnTitle={isRunnerPlanDetails ? "Update" : 'Submit'}
                                                type="submit"
                                                sx={{
                                                    width: '150px'
                                                }}
                                                loading={isLoading}
                                                onSubmit={() => formik.handleSubmit}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Grid>
                    {window.sessionStorage.getItem('PlanID') && <Grid item xs={12} md={8}>
                        <Box sx={{
                            background: '#EFE9FF80',
                            padding: { xs: "15px", lg: "20px" },
                            borderRadius: '8px'
                        }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        width: '100%'
                                    }}>
                                        <Button
                                            sx={{
                                                width: "190px",
                                                borderRadius: "15px",
                                                textTransform: "none",
                                                fontSize: "16px",
                                                background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                                                color: "#fff",
                                                padding: "0",
                                                margin: "0",
                                                height: '51px',
                                                "&:hover": {
                                                    background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                                                    color: "black",
                                                },
                                                fontFamily: "Outfit",
                                            }}
                                            onClick={() => setIsSelectedWorkoutModel(true)}
                                        >
                                            Add WorkOut Details
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <PlanWorkOutList
                                        isRunnerPlanDetails={isRunnerPlanDetails}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>
            <AddWorkoutModel
                open={isSelectedWorkoutModel}
                onClose={() => { setIsSelectedWorkoutModel(false); }}
                handleOnClose={() => { setIsSelectedWorkoutModel(false) }}
                isWorkoutDetailsList={isWorkoutDetailsList}
                checkedWorkOut={checkedWorkOut}
                setCheckedWorkOut={setCheckedWorkOut}
                searchWorkOut={searchWorkOut}
                setSearchWorkOut={setSearchWorkOut}
                handleOnClickWorkOutIsAdded={handleOnClickWorkOutIsAdded}
                pageSize={pageSize}
                page={page}
                count={count}
                handlePageChange={handlePageChange}
            />
        </Fragment>
    );
};

export default AddRunnerPlan;
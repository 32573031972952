import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomThumbUpload from "../../../Components/CustomFileUpload/UploadImage";
import CustomSubmitButton from "../../../Components/CustomSubmitButton/CustomSubmitButton";
import AxiosInstance from "../../../Config/AxiosInstance";
import APILIST from "../../../Config/ApiList";
import Constant from "../../../Utils/Constant";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import CustomDateTimePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import CUstomTimePicker from "../../../Components/CustomTimePicker/CustomTimePicker";
import moment from "moment";
import dayjs from "dayjs";
const AddChallengesEvent = (props) => {
  const {
    open,
    onClose,
    typeCheck,
    editChallengesEventData,
    handleOnSubmit,
    handleOnClose,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedThumb, setSelectedThumb] = useState(null);
  const [selectedThumbFile, setSelectedThumbFile] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedBannerFile, setSelectedBannerFile] = useState(null);
  const typeList = [
    {
      id: "1",
      name: "Challenges",
      label: "Challenges",
    },
    {
      id: "2",
      name: "Events",
      label: "Events",
    },
  ];
  const handleOnChangeThumb = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(
          file.type
        )
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedThumbFile(file);
          setSelectedThumb(videoUrl);
          formik.setFieldValue("thumb", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload Only(PNG.JPG,JPEG)");
      }
    }
  }, []);
  const handleOnChangeBanner = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/webp", "image/jpg"].includes(
          file.type
        )
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedBannerFile(file);
          setSelectedBanner(videoUrl);
          formik.setFieldValue("badge", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload Only(PNG.JPG,JPEG)");
      }
    }
  }, []);
  useEffect(() => {
    if (typeCheck === "EDIT" && editChallengesEventData) {
      console.log('editChallengesEventData',editChallengesEventData);
      
      formik.setValues({
        type: editChallengesEventData?.type || 0,
        title: editChallengesEventData?.title || "",
        description: editChallengesEventData?.description || "",
        location: editChallengesEventData?.location || "",
        selectDate:dayjs(editChallengesEventData?.date) || dayjs(),
        selectTime: dayjs(editChallengesEventData?.time, "HH:mm:ss") || dayjs(),


        thumb: editChallengesEventData?.cover_img || null,
        badge: editChallengesEventData?.badge || null,
      });

      if (editChallengesEventData?.cover_img) {
        setSelectedThumb(
          `${Constant.BASE_URL}${editChallengesEventData?.cover_img}`
        );
      }

      if (editChallengesEventData?.badge) {
        setSelectedBanner(
          `${Constant.BASE_URL}${editChallengesEventData?.badge}`
        );
      }
    } else {
      formik.resetForm();
      setSelectedThumb(null);
      setSelectedThumbFile(null);
      setSelectedBanner(null);
      setSelectedBannerFile(null);
    }
  }, [typeCheck]);

  const formik = useFormik({
    initialValues: {
      type: 0,
      title: "",
      description: "",
      location: "",
      selectDate: dayjs(),
      selectTime: dayjs(),
      thumb: null,
      badge: null,
    },
    validationSchema: Yup.object().shape({
      type: Yup.mixed()
        .test("is-not-zero", "Plan level is required!", (value) => value !== 0)
        .required("Plan level is required!"),
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      location: Yup.string().required("Location is required"),
      thumb: Yup.string().required("Thumb is required"),
      badge: Yup.string().required("Thumb is required"),
      selectDate: Yup.mixed().required("Please enter date here"),
      selectTime: Yup.mixed().required("Please enter time here"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData = new FormData();

          if (editChallengesEventData) {
            formData.append("id", editChallengesEventData?.id);
          }

          formData.append("title", values.title);
          formData.append("type", values.type);
          formData.append("description", values.description);
          formData.append("location", values.location);

          const formattedDate = moment(values.selectDate).format("YYYY-MM-DD");
          console.log('formattedDate', formattedDate);
          
          formData.append("date", formattedDate);
          const formattedTime = moment(values.selectTime).format("HH:mm:ss");
          formData.append("time", formattedTime);
          if (selectedThumbFile) {
            formData.append("cover_img", values.thumb);
          }

          if (selectedBannerFile) {
            formData.append("badge", values.badge);
          }

          const apiEndpoint = editChallengesEventData
            ? APILIST.UPDATE_EVENTS
            : APILIST.CREATE_EVENTS;

          const response = await AxiosInstance.post(apiEndpoint, formData);

          if (response && response.data?.s) {
            toast.success(
              editChallengesEventData
                ? "Updated Successfully!"
                : "Added Successfully!"
            );
            resetForm();
            setSelectedThumb(null);
            setSelectedThumbFile(null);
            handleOnSubmit();
          } else {
            toast.error("An unexpected error occurred");
          }
        } catch (error) {
          toast.error(error.message || "An error occurred");
        } finally {
          setIsLoading(false);
        }
      }
    },
  });
  console.log('formik',formik);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "20px 30px",
          height: "600px",
          overflow: "scroll",
          "::-webkit-scrollbar": { width: "0px", height: "0px" },
        },
        "& .MuiDialogContent-root": {
          overflowY: "initial",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#101010",
                fontFamily: "Outfit",
              }}
            >
              {typeCheck ? "Update" : "Add"} Challenges & Event
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                sx={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#101010",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#101010",
                    color: "#fff",
                  },
                }}
                onClick={handleOnClose}
              >
                <CloseIcon sx={{ color: "#fff", fontSize: "20px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            padding: "20px 0px 0px 0px",
          }}
        >
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: { xs: "16px", sm: "18px" },
                  mb: "8px",
                }}
              >
                Thumbnail
              </Typography>
              <CustomThumbUpload
                sx={{ height: "150px" }}
                accept="image/*"
                onDrop={handleOnChangeThumb}
                selectedFile={selectedThumb}
                onRemove={(e) => {
                  e.stopPropagation();
                  setSelectedThumb(null);
                  setSelectedThumbFile(null);
                  formik.setFieldValue("thumb", null);
                }}
                isLoading={isLoading}
                errors={formik.errors.thumb}
              />
              {formik.touched.thumb && formik.errors.thumb ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {formik.errors.thumb}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: { xs: "16px", sm: "18px" },
                  mb: "8px",
                }}
              >
                Banner
              </Typography>
              <CustomThumbUpload
                sx={{ height: "150px" }}
                accept="image/*"
                onDrop={handleOnChangeBanner}
                selectedFile={selectedBanner}
                onRemove={(e) => {
                  e.stopPropagation();
                  setSelectedBanner(null);
                  setSelectedBannerFile(null);
                  formik.setFieldValue("badge", null);
                }}
                isLoading={isLoading}
                errors={formik.errors.badge}
              />
              {formik.touched.badge && formik.errors.badge ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {formik.errors.badge}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: { xs: "16px", sm: "18px" },
                  mb: "8px",
                }}
              >
                Date
              </Typography>
              <CustomDateTimePicker
                name="selectDate"
                value={formik.values.selectDate}
                onChange={(newValue) => {
                  formik.setFieldValue("selectDate", newValue);
                }}
                errors={formik.touched.selectDate && formik.errors.selectDate}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  color: "black",
                  fontSize: "18px",
                  background: "white",
                  fontFamily: "Outfit",
                  outline:
                    formik.touched.selectTime && formik.errors.selectTime
                      ? "1px solid red"
                      : "1px solid #D0BCFF",
                  ".MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    border: "none",
                  },
                }}
                isLoading={isLoading}
              />
              {formik.touched.selectDate && formik.errors.selectDate ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.selectDate}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "400",
                  textAlign: "left",
                  fontSize: { xs: "16px", sm: "18px" },
                  mb: "8px",
                }}
              >
                Time
              </Typography>
              <CUstomTimePicker
                name="selectTime"
                inputFormat="hh:mm:ss"
                value={formik.values.selectTime}
                onChange={(newValue) => {
                  formik.setFieldValue("selectTime", newValue);
                }}
                errors={formik.touched.selectTime && formik.errors.selectTime}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  color: "black",
                  fontSize: "18px",
                  background: "white",
                  fontFamily: "Outfit",
                  outline:
                    formik.touched.selectTime && formik.errors.selectTime
                      ? "1px solid red"
                      : "1px solid #D0BCFF",
                  ".MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    border: "none",
                  },
                }}
              />
              {formik.touched.selectTime && formik.errors.selectTime ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  {formik.errors.selectTime}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDropDown
                Title={"Type"}
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                placeholder={"Select Type"}
                menuList={typeList}
                onBlur={formik.handleBlur}
                getLabel={(item) => item}
                onKeyUp={formik.handleBlur}
                errors={formik.touched.type && formik.errors.type}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: formik.errors.title
                      ? "1px solid red"
                      : "1px solid #BB9EFF",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label="Title"
                type="text"
                placeholder="Title"
                name="title"
                value={formik.values.title}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                errors={formik.touched.title && formik.errors.title}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: formik.errors.title
                      ? "1px solid red"
                      : "1px solid #BB9EFF",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label="Location"
                type="text"
                placeholder="Locaion"
                name="location"
                value={formik.values.location}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                errors={formik.touched.location && formik.errors.location}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: formik.errors.location
                      ? "1px solid red"
                      : "1px solid #BB9EFF",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label="Description"
                type="text"
                placeholder="Description"
                name="description"
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                errors={formik.touched.description && formik.errors.description}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                multiline={true}
                rows={3}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    border: formik.errors.description
                      ? "1px solid red"
                      : "1px solid #BB9EFF",
                    color: "black",
                    fontSize: "18px",
                    background: "white",
                    fontFamily: "Outfit",
                  },
                }}
                style={{ color: "black", fontSize: "18px" }}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSubmitButton
                btnTitle={typeCheck ? "Update" : "Add"}
                loading={isLoading}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </Dialog>
  );
};

export default AddChallengesEvent;

import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography } from "@mui/material";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import CustomAction from '../../../Components/CustomAction/CustomAction';
import AxiosInstance from '../../../Config/AxiosInstance';
import APILIST from '../../../Config/ApiList';
import { deleteDoc, doc } from "firebase/firestore";
import { deleteUser, sendPasswordResetEmail } from "firebase/auth";
import { AUTH_LOGIN, DATABASE } from '../../../Config/firebase';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddSubAdmin from './AddSubAdmin';
import SubAdminRightModel from './SubAdminRightModel';
const SubAdmin = () => {
  const pageSize = 30;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [subAdminList, setSubAdminList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [showSubAdminModel, setShowSubAdminModel] = useState(false);
  const [selectSubAdminDetails, setSelectedSubAdminDetails] = useState({});
  const [subAdminRigthModel, setSubAdminRigthModel] = useState(false);
  const [subAdminRightAccessId, setSubAdminRightAccessId] = useState(null);
  const columns = [
    { field: "rowid", headerName: "Sr.No.", minWidth: 100, },
    {
      field: "banner",
      headerName: "Profile",
      minWidth: 50,
      renderCell: (params) => {
        return (
          <AccountCircleIcon sx={{ fontSize: '40px' }} />
        )
      }
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      renderCell: (params) => (params?.row?.email ? params?.row?.email : "--")
    },
    {
      field: "user_name",
      headerName: "User Name",
      minWidth: 250,
      renderCell: (params) => (
        <Tooltip title={params?.row?.user_name || "--"}>
          <span>{params?.row?.user_name || "--"}</span>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <CustomAction
              showAccount={true}
              showView={true}
              showPassword={true}
              isPassword={() => handleResetPassword(params?.row)}
              isShowAccount={() => handleAccountAccessId(params?.row?.id)}
              handleOnClose={() => setSubAdminRigthModel(false)}
            />
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    getSubAdminList();
  }, [page]);
  const getSubAdminList = async () => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(APILIST.GET_SUB_ADMIN, {
        params: {
          count: page * pageSize,
          offset: pageSize,
        }
      });
      if (response && response?.data?.s) {
        const Rowdata = response?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setSubAdminList(Rowdata);
        setCount(response?.data?.c || 0);
      } else {
        setSubAdminList([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleOnSubmit = () => {
    setTypeCheck(null);
    getSubAdminList();
    setShowSubAdminModel(false);
  }
  const handleResetPassword = async (row) => {
    Swal.fire({
      title: "Are you sure you want to reset the password?",
      text: "This will send a reset password link to the user's email.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, send reset link",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await sendPasswordResetEmail(AUTH_LOGIN, row?.email);
          Swal.fire({
            title: "Reset Link Sent",
            text: `A password reset link has been sent to ${row?.email}.`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        } catch (error) {
          console.log("Error sending reset password email: ", error);
          Swal.fire({
            title: "Error",
            text: "There was an issue sending the reset password email. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
  const handleAccountAccessId = async (id) => {
    setSubAdminRigthModel(true);
    setSubAdminRightAccessId(id);
  }
  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        padding: '15px'
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: '16px'
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "30px" },
              fontWeight: "500",
              paddingBottom: "10px",
              fontFamily: "Outfit",
            }}
          >
            SubAdmin
          </Typography>
          <Button
            sx={{
              width: "150px",
              borderRadius: "15px",
              textTransform: "none",
              fontSize: "16px",
              background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
              color: "black",
              padding: "0",
              margin: "0",
              height: '51px',
              "&:hover": {
                background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                color: "black",
              },
              fontFamily: "Outfit",
            }}
            onClick={() => setShowSubAdminModel(true)}
          >
            Add SubAdmin
          </Button>
        </Box>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={subAdminList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              totalCount={count}
              handlePageChange={handlePageChange}
            />
          </Box>
        </Box>
      </Box>
      <AddSubAdmin
        open={showSubAdminModel}
        onClose={() => setShowSubAdminModel(false)}
        handleOnClose={() => setShowSubAdminModel(false)}
        handleOnSubmit={handleOnSubmit}
        selectSubAdminDetails={selectSubAdminDetails}
        
      />
      <SubAdminRightModel
        open={subAdminRigthModel}
        onClose={() => setSubAdminRigthModel(false)}
        handleOnClose={() => {
          setSubAdminRigthModel(false);
          getSubAdminList();
        }}
        subAdminRightAccessId={subAdminRightAccessId}
      />
    </Fragment>
  );
};

export default SubAdmin;

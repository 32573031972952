import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import PlanWorkOutList from "./PlanWorkOutList";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box padding={"20px"}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (id) => {
    return {
        id: `simple-tab-${id}`,
        "aria-controls": `simple-tabpanel-${id}`,
    };
};

const AddWorkOutWeekTabs = (props) => {
    const {
        isSelectedDay,
        isSelectedDays,
        workoutPlanAllDetails,
        handleSubmitWorkout,
        setIsOpenAddWorkOutModel,
        onDeleteUpdate,
        handleDeletePlandetailsDayAllPosition,
        isOpenAddWorkOutModel
    } = props;

    const [value, setValue] = useState(0);
    const [selectedWeekID, setSelectedWeekID] = useState(null);
    useEffect(() => {
        const { week_data = [] } = workoutPlanAllDetails || {};
        if (week_data?.length > 0) {
            setSelectedWeekID(week_data[0]?.id);
        }
    }, [workoutPlanAllDetails]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem("CurrentActive",newValue)
    };
    const handleItemClick = (id) => {
        setSelectedWeekID(id);
    };
    const selectedWeekData = workoutPlanAllDetails?.week_data?.find(item => item?.id === selectedWeekID) || {};

    return (
        <Box sx={{ display: "flex", height: "100vh", gridGap: '20px' }}>
            <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="content management tabs"
                variant="scrollable"
                sx={{
                    minWidth: "200px",
                    maxWidth: "200px",
                    background: "#EFE9FF80",
                    borderRadius: '16px',
                    padding: "0px 10px",
                    "& .MuiTab-root": {
                        fontSize: { xs: "12px", sm: "14px", md: "16px" },
                        textTransform: "capitalize",
                        "&.Mui-selected": {
                            background: "linear-gradient(180deg, #B191FF 0%, #D0BCFF 100%)",
                            color: "#000",
                            borderRadius: '8px',
                        },
                    },
                    "& .MuiTabs-indicator": {
                        display: 'none',
                    },
                }}
            >
                {workoutPlanAllDetails?.week_data?.map((item, index) => (
                    <Tab
                        key={index}
                        label={item?.week_name}
                        {...a11yProps(item?.id)}
                        onClick={() => handleItemClick(item.id)}
                    />
                ))}
            </Tabs>
            <Box sx={{
                flexGrow: 1, background: "#EFE9FF80",
                borderRadius: '16px',
                padding: "10px 10px",
            }}>
                {workoutPlanAllDetails?.week_data?.length > 0 && workoutPlanAllDetails.week_data.map((item, index) => (
                    <TabPanel value={value} index={index} key={index}>
                        <PlanWorkOutList
                            workoutPlanAllDetails={workoutPlanAllDetails}
                            selectedWeekID={selectedWeekID}
                            handleSubmitWorkout={handleSubmitWorkout}
                            setIsOpenAddWorkOutModel={setIsOpenAddWorkOutModel}
                            isOpenAddWorkOutModel={isOpenAddWorkOutModel}
                            selectedWeekData={selectedWeekData}
                            isSelectedDays={isSelectedDays}
                            isSelectedDay={isSelectedDay}
                            onDeleteUpdate={onDeleteUpdate}
                            handleDeletePlandetailsDayAllPosition={handleDeletePlandetailsDayAllPosition}
                        />
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
};

export default AddWorkOutWeekTabs;
